import { fetchUtils } from 'react-admin3'
import { prop } from 'ramda'
import jsonServerProvider from 'eoskin-ra-data-json-server3'
import { getToken } from './authProvider'

const httpClient = async (url, options = {}) => {
  if (!options.headers) {
    // eslint-disable-next-line no-param-reassign
    options.headers = new Headers({ Accept: 'application/json' })
  }
  options.headers.set('Authorization', `Token ${getToken()}`)
  const [originalUrl, qs = ''] = url.split('?')
  const fixedURL = fixURL(originalUrl)
  const fixedOptions = {
    ...options,
    method: options.method === 'PUT' ? 'PATCH' : options.method,
  }
  const {
    status, headers, body, json,
  } = await fetchUtils.fetchJson(`${fixedURL}?${qs}`, fixedOptions)

  return {
    status,
    headers,
    body,
    json: prop('results', json) || json,
  }
}
const fixURL = url => (!url.endsWith('/') ? `${url}/` : url)
// TODO uncomment this to use the backend
export const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/trainer`

// Uncomment this to use mocked data
// export const endpoint = `${process.env.REACT_APP_PLACEHOLDER_URL}`
export default jsonServerProvider(endpoint, httpClient)
