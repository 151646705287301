import { put, select } from 'redux-saga/effects'
import dataURItoBlob from '../../../lib/dataURItoBlob'
import { showImage, createImage, uploadImage } from '../actions'


function* uploadPhoto(action) {
  const { payload: { photo } } = action
  try {
    const { api_key: apikey } = yield select(studentApiKeySelector)
    const response = yield put.resolve(createImage(apikey))
    const { data: { upload_link: link } } = response

    const photoBlob = dataURItoBlob(photo)
    yield put(uploadImage(link, photoBlob))
    yield put(showImage(photo))
  } catch (e) {
    console.error(e)
  }
}
export default uploadPhoto

const studentApiKeySelector = ({ student: { authenticate } }) => authenticate.data
