// @flow
import qs from 'qs'
import { delay } from 'redux-saga'
import * as R from 'ramda'
import {
  fork, put,
  takeLatest, all,
} from 'redux-saga/effects'
import { push } from 'react-router-redux';
import { SubmissionError } from 'redux-form';

import {
  getAuthenticationCode,
  login, confirmAuthentication,
} from './actions'


function* requestAuthenticationCode(): Generator<*, *, *> {
  const { search } = window.location
  const fixedQS = search.replace(/[+]/g, '%2B')
  const { username, courseID, moduleCode } = qs.parse(fixedQS.slice(1))
  yield delay(10) // wait for dehydrate
  try {
    yield put.resolve(getAuthenticationCode(username, courseID, moduleCode))
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* authenticateStudent(action): Generator<*, *, *> {
  yield delay(100)

  const {
    code,
    username,
    course_id: courseID,
    module_code: moduleCode,
  } = action.payload;

  try {
    yield put.resolve(
      confirmAuthentication(username, courseID, moduleCode, code),
    )
    yield put(login.success())
    yield put(push('speedtest/'))
  } catch (error) {
    const responseError = formatError(error)
    const formError = new SubmissionError({
      _error: responseError.detail || 'Login failed, please check the code and try again',
      ...responseError,
    });
    yield put(login.failure(formError));
  }
}
const formatError = R.pipe(
  R.pathOr({}, ['error', 'data']),
  R.map((i: string | Array<string>) => (typeof i === 'string' ? i : R.head(i))),
)

function* saga(): Generator<*, *, *> {
  yield all([
    fork(requestAuthenticationCode),
    takeLatest(login.REQUEST, authenticateStudent),
  ])
}
export default saga
