import { put } from 'redux-saga/effects'
import { startTestSession } from '../actions'
import startAuthentication from '../Authentication/saga'

const routes = {
  '/student/': startAuthentication,
  '/student/test/': function* saga() {
    yield put(startTestSession())
  },
}

export default routes
