import {
  all, fork, put, takeEvery,
} from 'redux-saga/effects';
import {
  adminSaga,
  CRUD_CREATE_FAILURE,
  CRUD_UPDATE_FAILURE,
} from 'react-admin3'
import englishMessages from 'ra-language-english'
import { stopSubmit } from 'redux-form';

import { authProvider } from './authProvider'
import dataProvider from './dataProvider'

const i18nProvider = () => englishMessages

export default function* saga() {
  yield all([
    fork(errorSagas),
    fork(adminSaga(dataProvider, authProvider, i18nProvider)),
  ])
}

function* errorSagas() {
  yield takeEvery([
    CRUD_CREATE_FAILURE,
    CRUD_UPDATE_FAILURE,
  ], crudCreateFailure);
}

function* crudCreateFailure(action) {
  const violations = extractViolations(action.payload);
  yield put(stopSubmit('record-form', violations));
}

const extractViolations = ({ non_field_errors: _error, ...response }) => ({
  _error, ...response,
})
