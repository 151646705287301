// @flow
import Centrifuge from 'centrifuge'
import { put, call, take } from 'redux-saga/effects'
import { eventChannel, END } from 'redux-saga'
import { centrifugeMessage } from '../actions'

type LoginAction = {
  data: {
    centrifugo_token: string,
    client_id: string
  }
}

function* startCentrifuge(action: LoginAction): Generator<*, void, *> {
  const { data: { centrifugo_token: token, client_id: clientID } } = action;
  try {
    const {
      channel: completeChannel,
    } = yield call(connectCentrifuge, token, clientID)
    while (true) {
      const message = yield take(completeChannel)
      yield put(centrifugeMessage(message))
    }
  } catch (e) {
    console.error('centrifugo saga error', e)
  }
}
export default startCentrifuge

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || ''
const WS_BACKEND_URL = BACKEND_URL.replace('http', 'ws');

export const connectCentrifuge = (token: string, clientID: string) => {
  const centrifugoLink = `${WS_BACKEND_URL}/centrifugo/connection/websocket`
  const centrifuge = new Centrifuge(centrifugoLink, {
    subscribeEndpoint: `${BACKEND_URL}/api/rest-auth/login/`,
  })

  centrifuge.setToken(token);
  const subscription = centrifuge.subscribe(`student#${clientID}`)

  const channel = eventChannel((emitter) => {
    centrifuge.on('disconnect', ({ reconnect }) => {
      if (!reconnect) {
        emitter(END)
      }
    })

    subscription.on('error', console.error);
    subscription.on('publish', message => emitter(message))
    subscription.on('subscribe', () => console.log('subscribed'))
    subscription.on('join', ({ info }) => {
      // eslint-disable-next-line no-underscore-dangle
      if (info.client !== centrifuge._clientID) {
        emitter({ data: 'concurrent' })
        emitter(END)
      }
    })

    centrifuge.connect();

    return () => centrifuge.disconnect()
  })

  return { channel, centrifuge, subscription }
}
