import {
  all, takeEvery,
  takeLatest, fork,
  select,
} from 'redux-saga/effects'
import { router } from 'redux-saga-router'
import history from '../../../history'

import speedtest from './speedTest'
import uploadPhoto from './uploadPhoto'
import watchTestSession from './testSession'
import startStudentNotifications from './notifications'
import startCentrifuge from './centrifugo'
import routes from './routes'


export default function* studentSagas() {
  yield all([
    fork(router, history, routes),
    takeEvery('START_SPEED_TEST', speedtest),
    takeEvery('UPLOAD_PHOTO', uploadPhoto),
    takeLatest('START_TEST_SESSION', startTestSession),
  ])
}

function* startTestSession() {
  const { student: { authenticate } } = yield select()
  yield all([
    takeLatest('CONNECTION_READY', startStudentNotifications),
    fork(startCentrifuge, authenticate),
    fork(watchTestSession),
  ])
}
