import { handleActions, combineActions } from 'redux-actions'
import {
  newConnectionAttempt,
  changeConnectionStatus, startConnecting, connectionReady,
  sesssionComplete,
} from './actions'

export const defaultState = {
  attempt: 0,
  status: '',
  ip: '',
  durationSeconds: null,
  terminateAtTimestamp: null,
  completedAt: null,
};

const rdpConnection = handleActions({
  [newConnectionAttempt]: ({ attempt, ...state }) => (
    { ...state, attempt: attempt + 1 }
  ),
  [combineActions(changeConnectionStatus, connectionReady)]: (
    state, { payload: { status } },
  ) => {
    if (status === 'ready' && state.status !== 'connected') {
      return state
    }
    return {
      ...state,
      status,
      completedAt: null,
    }
  },
  [startConnecting]: (
    state, { payload: { ip, terminateAt, durationSeconds } },
  ) => ({
    ...state,
    ip,
    durationSeconds,
    terminateAtTimestamp: terminateAt,
    completedAt: null,
  }),
  [sesssionComplete]: (
    state, { payload: { status } },
  ) => ({
    ...state,
    status,
    completedAt: Date.now() / 1000,
  }),
}, defaultState)

export default rdpConnection
