import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { requestsPromiseMiddleware } from 'redux-saga-requests';
import { routerMiddleware } from 'react-router-redux';
import history from '../history';
import reducer from './reducers';
import mySaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  reducer,
  applyMiddleware(
    requestsPromiseMiddleware({ auto: true }),
    sagaMiddleware,
    routerMiddleware(history),
  ),
);

sagaMiddleware.run(mySaga);

export default store;
