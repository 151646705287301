import { put, call } from 'redux-saga/effects'
import { completeSpeedTest } from '../actions'
import runSpeedTest from '../../../lib/speedtest'

function* speedtest() {
  try {
    const testResult = yield call(runSpeedTest)
    yield put(completeSpeedTest(testResult))
  } catch (e) {
    console.error(e)
  }
}

export default speedtest
