// @flow
import Network from 'network-js/dist/network.min'

const settings = {
  endpoint: `${process.env.REACT_APP_RDP_PROXY_URL || ''}speedTest/`,
  download: {
    delay: 3000,
  },
};
const net = new Network(settings);

export default async () => {
  let averageLatency = 200 // Slow latency
  let averageDownloadSpeed = 0
  try {
    averageLatency = await timedoutTest(net.latency)
  // eslint-disable-next-line no-empty
  } catch (error) {}
  try {
    averageDownloadSpeed = await timedoutTest(net.download)
  // eslint-disable-next-line no-empty
  } catch (error) {}

  return {
    averageLatency,
    averageDownloadSpeed,
  };
}

type NetworkTest = {
  on: (event: string, handler: Function) => NetworkTest,
  start: Function,
  abort: Function,
}

const timedoutTest = async (netTest: NetworkTest) => {
  const subTestPromise = runSubTest(netTest)
  const resolved = await Promise.race([subTestPromise, delay(10)])

  if (resolved === 'timeout') {
    netTest.abort()
  }

  const resolvedAfterAbort = await Promise.race([subTestPromise, delay(3)])
  if (resolvedAfterAbort === 'timeout') {
    return 0;
  }
  return resolvedAfterAbort[0]
}
const runSubTest = (netTest: NetworkTest) => new Promise(resolve => netTest.on('end', (...args) => resolve(args))
  .start())

const delay = (seconds: number) => new Promise(
  resolve => setTimeout(() => resolve('timeout'), seconds * 1000),
)
