// @flow
import { createActions } from 'redux-actions'
import type { SpeedTestResult } from './Domain'

export const {
  uploadPhoto,
} = createActions({
  UPLOAD_PHOTO: (photo: string) => ({ photo }),
})

export const {
  startSpeedTest,
  completeSpeedTest,
  abortSpeedTest,
} = createActions({
  START_SPEED_TEST: () => ({}),
  COMPLETE_SPEED_TEST: (result: SpeedTestResult) => ({ result }),
  ABORT_SPEED_TEST: () => ({}),
})


export const {
  showMessage, showImage,
  hideNotification,
} = createActions({
  SHOW_MESSAGE: (message: string) => ({ message }),
  SHOW_IMAGE: (image: string) => ({ image }),
  HIDE_NOTIFICATION: (id: string) => ({ id }),
})

export const {
  centrifugeMessage,
  startTestSession,
} = createActions({
  CENTRIFUGE_MESSAGE: (message: any) => message,
  START_TEST_SESSION: () => ({}),
})

export const createImage = (apiKey: string) => ({
  type: 'CREATE_IMAGE',
  request: {
    url: '/api/student/photo/',
    method: 'post',
    headers: {
      Authorization: `Token ${apiKey}`,
    },
  },
})
export const uploadImage = (url: string, photo: Blob) => {
  const [baseURL, query] = url.split('?')
  const fixedQS = query.replace(/[+]/g, '%2B')

  return {
    type: 'UPLOAD_IMAGE',
    request: {
      url: `${baseURL}?${fixedQS}`,
      method: 'put',
      body: photo,
      responseType: 'text',
    },
  }
}
