import { handleActions, combineActions } from 'redux-actions'
import { combineReducers } from 'redux'

import nanoid from 'nanoid'

import {
  startSpeedTest, completeSpeedTest,
  showMessage, showImage, hideNotification,
  abortSpeedTest,
} from './actions'
import {
  authenticate, authenticationCode,
} from './Authentication/reducers'

export const defaultState = {
  speedtest: {
    pending: false,
    result: null,
  },
  notifications: [],
};

const speedtest = handleActions({
  [startSpeedTest]: () => ({
    pending: true,
    result: null,
  }),
  [completeSpeedTest]: (
    state, { payload: { result } },
  ) => ({
    pending: false,
    result,
  }),
  [abortSpeedTest]: () => ({
    pending: false,
    result: null,
  }),
}, defaultState.speedtest)

const notifications = handleActions({
  [combineActions(showMessage, showImage)]: (
    state, { payload: { message = '', image = '' } },
  ) => ([...state, { message, image, id: nanoid() }]),
  [hideNotification]: (
    state, { payload: { id: idToHide } },
  ) => state.filter(({ id }) => id !== idToHide),
}, defaultState.notifications)


const rootReducer = combineReducers({
  speedtest,
  notifications,
  authenticate,
  authenticationCode,
})


export default rootReducer
