import { delay } from 'redux-saga'
import {
  put, all, select,
} from 'redux-saga/effects'
import { push } from 'react-router-redux';
import { showMessage } from '../actions'

function* startStudentNotifications() {
  const {
    durationSeconds, terminateAtTimestamp,
  } = yield select(studentDurationAndTimer)

  yield all([
    scheduleMessageNotification(
      middleInSeconds(terminateAtTimestamp, durationSeconds),
      'You have a half of the time',
    ),
    scheduleMessageNotification(
      minus15MinutesInSeconds(terminateAtTimestamp),
      'You have 15 mintues left',
    ),
    scheduleTimeout(terminateAtTimestamp),
  ])
}
export default startStudentNotifications

function* scheduleMessageNotification(scheduleAt, message) {
  const period = scheduleAt * 1000 - Date.now()
  if (period > 0) {
    yield delay(period)
    yield put(showMessage(message))
  }
}
function* scheduleTimeout(scheduleAt) {
  try {
    const timeLeft = scheduleAt * 1000 - Date.now()
    if (timeLeft > 0) {
      yield delay(timeLeft)
    }
    yield put(push('../timeout/'))
  } catch (e) {
    console.error(e)
  }
}

const studentDurationAndTimer = ({
  rdpConnection: { durationSeconds, terminateAtTimestamp } = {},
}) => ({ durationSeconds, terminateAtTimestamp })
const middleInSeconds = (end, durationSeconds) => end - durationSeconds / 2
const minus15MinutesInSeconds = end => end - 15 * 60
